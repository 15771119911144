<template>
  <div class="mod-brand">
    <el-tabs tab-position="left" style="height: calc(100vh - 136px)">
      <el-form
        :inline="true"
        :model="searchForm"
        @keyup.enter.native="getGoodsDataList()"
      >
        <el-form-item>
          <el-date-picker
            v-model="searchForm.time"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item>
          <el-button @click="getGoodsDataList()"> 查询 </el-button>
          <json-excel
            name="餐品数量统计"
            class="export"
            :fetch="singleexportExcel"
          >
            <el-button>导出Excel</el-button>
          </json-excel>
        </el-form-item>
      </el-form>
      <el-table :data="goodsList" border height="75vh" style="width: 100%">
        <el-table-column
          prop="goodsName"
          header-align="center"
          align="center"
          label="单品名称"
        />
        <el-table-column
          prop="name"
          header-align="center"
          align="center"
          label="单品名称"
        />
        <el-table-column
          prop="number"
          header-align="center"
          align="center"
          label="单品数量"
        />
      </el-table>
    </el-tabs>
  </div>
</template>
<script>
import moment from 'moment';
import JsonExcel from 'vue-json-excel';
export default {
  components: {
    JsonExcel,
  },
  data() {
    return {
      searchForm: {
        time: [],
        shopid: null,
        tomorowTime: null,
      },
      options: [],
      totalPrice: 0,
      totalNum: 0,
      dataList: [],
      goodsDataList: [],
      goodsList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  created() {
    this.getShopList();
  },
  activated() {
    this.getOrderList();
    this.getDataList();
    this.getGoodsDataList();
  },
  methods: {
    //获取所有冷柜列表
    getShopList() {
      this.$http({
        url: '/mall/order/searchShops',
        method: 'get',
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.options = data.list;
        } else {
          this.options = [];
        }
      });
    },
    // 获取订单列表数据
    getOrderList() {
      if (this.searchForm.time.length === 0) {
        this.searchForm.time = [
          moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        ];
      }
      this.$http({
        url: '/mall/order/queryPageByOrderTime',
        method: 'get',
        params: {
          startTime: this.searchForm.time[0],
          endTime: this.searchForm.time[1],
          shopid: this.searchForm.shopid,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log(data);
          this.dataList = data.vo.goodList;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 获取数据列表
    getDataList() {
      if (this.searchForm.time.length === 0) {
        this.searchForm.time = [
          moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        ];
      }
      this.totalNum = 0;
      this.$http({
        url: '/mall/order/goodStatistics',
        method: 'get',
        params: {
          startDate: this.searchForm.time[0],
          endDate: this.searchForm.time[1],
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          data.vo.goodList.forEach((item) => {
            this.totalNum += parseFloat(item.sumNum);
          });
          this.goodsDataList = data.vo.goodList;
          this.totalPrice = data.vo.sumPrice;
        } else {
          this.goodsDataList = [];
          this.totalPrice = 0;
          this.totalNum = 0;
        }
      });
    },
    // 获取单品数据列表
    getGoodsDataList() {
      if (this.searchForm.time.length === 0) {
        this.searchForm.time = [
          moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
          moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        ];
      }
      this.$http({
        url: '/mall/newOrder/singleGoodsNumbers',
        method: 'get',
        params: {
          startDate: this.searchForm.time[0],
          endDate: this.searchForm.time[1],
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.goodsList = data.list;
        } else {
          this.goodsList = [];
        }
      });
    },
    // 导出订单列表数据
    async orderExportExcel() {
      const { data } = await this.$http({
        url: '/mall/order/export',
        method: 'get',
        params: {
          nickname: this.searchForm.nickname,
          orderSn: this.searchForm.orderSn,
          payStatus: this.searchForm.payStatus,
          shippingStatus: this.searchForm.shippingStatus,
          consignee: this.searchForm.consignee,
          mobile: this.searchForm.mobile,
          addDate: this.searchForm.addDate,
        },
      });
      if (data && data.code === 0) {
        if (data.list.length === 0) {
          this.$message({
            message: '需导出的订单为空',
            type: 'warning',
            duration: 1500,
          });
        }
        return data.list;
      }
    },
    // 导出Excel
    async exportExcel() {
      const { data } = await this.$http({
        url: '/mall/order/exportStatistics',
        method: 'get',
        params: {
          startDate: this.searchForm.time[0],
          endDate: this.searchForm.time[1],
        },
      });
      if (data && data.code === 0) {
        if (data.list.length === 0) {
          this.$message({
            message: '需导出的订单为空',
            type: 'warning',
            duration: 1500,
          });
        }
        return data.list;
      }
    },
    // 单品数据导出
    async singleexportExcel() {
      const { data } = await this.$http({
        url: '/mall/newOrder/exportSingleGoodsNumbers',
        method: 'get',
        params: {
          startDate: this.searchForm.time[0],
          endDate: this.searchForm.time[1],
        },
      });
      if (data && data.code === 0) {
        if (data.list.length === 0) {
          this.$message({
            message: '需导出的订单为空',
            type: 'warning',
            duration: 1500,
          });
        }
        return data.list;
      }
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
  },
};
</script>
<style lang="scss">
.export {
  display: inline-block;
}
.totalNumList {
  position: relative;
  .totalPrice,
  .totalNum {
    color: #333;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin: 0 15px 22px 0;
    span {
      color: #00a2d4;
    }
  }
}
</style>
